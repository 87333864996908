import { useContext, useEffect, useState } from "react"
import { HashLink } from "react-router-hash-link"
import { DrawerContext } from "../contexts/DrawerContext"
import { motion } from "framer-motion"
import { ReiterType } from "../Types"
import { useKeycloak } from "@react-keycloak/web"
import { Roles } from "../../auth/RoleMapping"
import { RenderWhenAuthorized } from "../authentication/RenderWhenAuthorized"

export default function Header() {
    const { keycloak } = useKeycloak()

    const trigger = useContext(DrawerContext)

    const [mini, setmini] = useState(false)

    const [wind, setWind] = useState("")

    const [url, seturl] = useState("")
    useEffect(() => {
        seturl(window.location.href)
    }, [window.location.href])

    const [windowSize, setwindowSize] = useState(window.innerWidth)
    useEffect(() => {
        function handleResize() {
            setwindowSize(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <>
            <motion.div
                initial={{
                    x: windowSize >= 1280 ? 0 : "-16rem",
                    width: "16rem",
                }}
                animate={{
                    x: trigger?.open && windowSize < 1280 ? "-16rem" : 0,
                    width: mini ? "5rem" : "16rem",
                }}
                transition={{
                    duration: 0.5,
                    type: "ease",
                }}
                className="h-screen bg-gray-500 flex flex-col items-center pt-16 absolute z-[10] xl:relative">
                <button className={`hidden text-base-100 lg:block absolute top-4 ${!mini && "left-4"}`} onClick={() => setmini(!mini)}>
                    {!mini && windowSize > 1280 ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    ) : (
                        mini &&
                        windowSize > 1280 && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>
                        )
                    )}
                </button>
                {!mini && <img alt="Besitzwerk Logo" className="w-full h-fit px-4 pb-8 object-cover" src="/besitzwerk_logo-removebg.png" />}
                <div className={`flex flex-col w-full h-fit pt-4 items-center`}>
                    <RenderWhenAuthorized requiresAll={[Roles.dashboard_read]}>
                        <NavReiter
                            trigger={() => trigger?.trigger(true)}
                            url={url}
                            mini={mini}
                            func={() => {
                                setWind("Dashboard")
                                seturl("/dashboard")
                            }}
                            text="Dashboard"
                            link="/dashboard"
                            open={wind}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z"
                                    />
                                </svg>
                            }
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.buchhaltung_read]}>
                        <NavReiter
                            trigger={() => trigger?.trigger(true)}
                            url={url}
                            mini={mini}
                            func={() => {
                                setWind("Buchhaltung")
                                seturl("/buchhaltung")
                            }}
                            text="Buchhaltung"
                            link="/buchhaltung"
                            open={wind}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    {" "}
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                                    />
                                </svg>
                            }
                        />
                    </RenderWhenAuthorized>
                    {/* <RenderWhenAuthorized requiresAll={[Roles.dashboard_read]}>
            <NavReiter trigger={() => trigger?.trigger(true)} url={url} mini={mini} func={() => {setWind('Aufgaben'); seturl('/aufgaben')}} text='Aufgaben' link='/aufgaben' open={wind} icon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" /></svg>}/>
          </RenderWhenAuthorized> */}
                    <RenderWhenAuthorized requiresAll={[Roles.mitarbeiter_read]}>
                        <NavReiter
                            trigger={() => trigger?.trigger(true)}
                            url={url}
                            mini={mini}
                            func={() => {
                                setWind("Mitarbeiter")
                                seturl("/mitarbeiter")
                            }}
                            text="Mitarbeiter"
                            link="/mitarbeiter"
                            open={wind}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                    />
                                </svg>
                            }
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.objekte_read]}>
                        <NavReiter
                            trigger={() => trigger?.trigger(true)}
                            url={url}
                            mini={mini}
                            func={() => {
                                setWind("Objekte")
                                seturl("/objekte")
                            }}
                            text="Objekte"
                            link="/objekte"
                            open={wind}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
                                    />
                                </svg>
                            }
                        />
                    </RenderWhenAuthorized>
                    <RenderWhenAuthorized requiresAll={[Roles.kunden_read]}>
                        <NavReiter
                            trigger={() => trigger?.trigger(true)}
                            url={url}
                            mini={mini}
                            func={() => {
                                setWind("Kunden")
                                seturl("/kunden")
                            }}
                            text="Kunden"
                            link="/kunden"
                            open={wind}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                                    />
                                </svg>
                            }
                        />
                    </RenderWhenAuthorized>
                    {/* <RenderWhenAuthorized requiresAll={[Roles.kunden_read]}>
                        <NavReiter
                            trigger={() => trigger?.trigger(true)}
                            url={url}
                            mini={mini}
                            func={() => {
                                setWind("Dokumente")
                                seturl("/dokumente")
                            }}
                            text="Dokumente"
                            link="/dokumente"
                            open={wind}
                            icon={
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                                    />
                                </svg>
                            }
                        />
                    </RenderWhenAuthorized> */}
                </div>
                <div className="flex flex-col absolute bottom-4 w-full">
                    <NavReiter
                        trigger={() => trigger?.trigger(false)}
                        mini={mini}
                        func={() => setWind("Hilfe")}
                        text="Hilfe"
                        link="/hilfe"
                        open={wind}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6">
                                {" "}
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                                />
                            </svg>
                        }
                    />
                    <NavReiter
                        trigger={() => trigger?.trigger(false)}
                        mini={mini}
                        func={() => {
                            keycloak.logout()
                            setWind("")
                        }}
                        text="Logout"
                        link="/loading"
                        open={wind}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                />
                            </svg>
                        }
                    />
                </div>
            </motion.div>

            {/* Button for Drawer */}
            <div className="xl:hidden fixed top-10 left-8 z-50 cursor-pointer" onClick={() => trigger?.trigger(!trigger?.open)}>
                <div className="border-2 border-base-200 w-12 h-12 flex items-center justify-center rounded-full">
                    {trigger?.open ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 text-base-200">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8 text-base-200">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                        </svg>
                    )}
                </div>
            </div>
        </>
    )
}

function NavReiter(props: ReiterType) {
    return (
        <HashLink to={props.link} className="w-full">
            <motion.div
                initial={{
                    paddingLeft: "2rem",
                    paddingRight: "2rem",
                }}
                animate={{
                    paddingLeft: props.mini ? "1rem" : "2rem",
                    paddingRight: props.mini ? "1rem" : "2rem",
                }}
                onClick={() => {
                    props.func()
                    props.trigger(true)
                }}
                className={`w-full text-lg text-base-100 flex h-14 cursor-pointer hover:bg-black/10 items-center  ${
                    props.mini ? "justify-center" : "justify-start"
                }`}>
                {props.icon}
                {!props.mini && (
                    <p className={`pl-4 font-semibold ${(props.open === props.text || props?.url?.endsWith(props.link)) && "underline"}`}>
                        {props.text}
                    </p>
                )}
            </motion.div>
        </HashLink>
    )
}
